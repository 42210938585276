@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);
html > body {
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,p,div,li,span {
  font-family: 'Open Sans', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width:900px) {
  html > body {
    padding-bottom: 70px
  }
}
body .header {padding: 10px;margin-bottom: 2px;background:#fff;box-shadow:0 2px 2px rgba(0,0,0,.2)}
body .header header{margin-left:5%;margin-right:5%;margin:auto}
body .header header .logo{max-width:60px;margin:auto}
@media only screen and (min-width:1200px){
  body .header header .logo{padding-left:18px}}
body .header header div,body .header header p{display:inline-block}
body .header header div .MuiListItem-root,body .header header p .MuiListItem-root{width:auto}body .header header .header-sub,body .header header .header-sub-right{float:right;max-width:inherit!important}body .header header .header-sub .MuiListItemIcon-root,body .header header .header-sub-right .MuiListItemIcon-root{max-width:inherit!important}body .header header nav{text-align:right}body .header header nav a span{font-size:20px;font-weight:700;color:#323232}

.background{
  background-image: url(/static/media/vaccination.72d5b8c3.jpg);
}

@media only screen and (max-width:900px) {
  body .header header .logo{max-width:260px;margin:auto}
  .background{
    height: 76px !important;
  }
  header{
    text-align: center;
  }
}
.red{height: 20px; background-color: #d50d3d;}

.former{
  text-align: center;
    padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  margin: auto;
}

@media only screen and (min-width:900px){
  .former div.former-box {
    float: left;
    padding-right: 30px;
  }
}

@media only screen and (max-width:900px){
  .goon {
    position: fixed !important;
    bottom: 60px !important;
    right: 10px !important;
    z-index: 100 !important;
  }

  .former div.former-box {
    width: 50%;
    margin: auto;
    margin-top: 10px;
  }

  .MuiStepper-horizontal{
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
    text-align: left !important;
  }
  .MuiStep-horizontal{
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  .MuiStep-horizontal .Mui-disable{
    display: none !important;
  }
}

.former div.former-box{
  text-align: center;
}

.subformer{
  max-width: 550px;
  margin: auto;
}

.former-box-select{
  background-color: #fff;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.former label {
  float:left;
  font-weight: 700 !important;
  padding-top: 7px;
  padding-right: 30px;
}

.former-box-booking-txt{
  color: #fff;
  font-weight: 700 !important;
  padding-top: 7px;
  display: block;
}

.former-box-submit{
  background-color: #000 !important;
  border-radius: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.former select {
  float:left;
  font-weight: 700 !important;
  padding-top: 7px;
  padding-right: 30px;

}

.logo{
  width: 200px;
}
@media only screen and (max-width:900px){
  .logo{
    width: 400px;
  }
}

body .content{margin-left:5%;margin-right:5%}
body .content .MuiTypography-h1{
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin-top: 60px;
}

body .homeText{
  max-width: 700px;
  margin: auto;
  font-size: 18px;
  margin-top: 40px;
  text-align: center;
}



@media only screen and (min-width:900px){
  .changer {  margin: auto; margin-top: 50px; margin-bottom: 50px; max-width: 80%;}
  .changer .changerImg{float: left; width: 20%}
  .changer .changerImg img{width: 100%}
  .changer .changerTxt{float: left; width: 70%; padding-left: 5%; padding-right: 5%}
}
@media only screen and (max-width:900px){
  .changer {  margin: auto; margin-top: 40px; margin-bottom: 10px; max-width: 80%;}
  .changer .changerImg{text-align: center}
  .changer .changerImg img{width: 50%}
  .changer .changerTxt{width: 90%; padding-left: 5%; padding-right: 5%; margin-top: 5%; text-align: center}
}

.boxViewFull{
  width: 20%;
  margin-right: 1%;
  margin-bottom: 20px;
  float: left;
}

@media only screen and (max-width:900px){
  .boxViewFull{
    width:100%;
  }
}
footer{
  background-color: #323232;
  padding-top: 20px;
  padding-bottom: 20px;
}

footer .footer{
  margin-left:5%;margin-right:5%
}

.foot-top-bar{
  width: 100%;
  position: fixed;
  bottom: 0px;
  background-color: #eee;
  z-index: 100;
}
.foot-top-bar nav{width: 100%}

.foot-top-bar span{font-size: 12px}


.purpleDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  border: 0px solid rgba(156, 136, 255, 0.7) !important;
}

.orangeDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  border: 2px solid rgba(219, 145, 60, 0.7) !important;
}

.yellowDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  border: 2px solid rgba(228, 231, 36, 0.7) !important;
}

.navyBlueDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  border: 2px solid rgba(52, 73, 94, 0.7) !important;
}


.Calendar__day{
  color: black !important;
}

.timbutto .MuiButton-containedSecondary{
  background-color: green !important;;
}

